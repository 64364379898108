.mapR{width: calc(100% - 7.44vw); border-radius: 2.34vw; overflow: hidden; background-color: #fbeedc; margin-top: 5vw; margin: 0 3.72vw; position: relative;}
.mapR .title{margin-bottom: 4vw;}
.mapR::after{position: absolute; content: ''; z-index: 3; top:0%; left:0vw; width: calc(31vw - 1.86vw + 3px); height: 100%; background-color: white; }
.mapR::before{position: absolute;background-color: #fbeedc; content: ''; top:0; left:calc(31vw - 1.86vw + 3px); width: 3%; height: 100%; z-index: 4; }
.xar{ height: 30vw; position: relative;}
.xar::after{position: absolute; content: ''; top:0%; left:0vw; width: 100%; height: 1vw; background-color: #fbeedc; }
.xar::before{position: absolute;background-color: #fbeedc; content: ''; bottom:0; left:0; width: 100%; height: 1vw; z-index: 3; }
.mapR *{ cursor:pointer !important}
.modal *{cursor:auto !important}
.modal{z-index: 3455;}
.xar *{transition: 0s !important;}
.map-modal_content{padding: 1vw; position: relative;}
.modal_x{position: absolute; right:1vw; top: 0.5vw; transition: 0s !important; font-size: 1.5vw; color:#3D4A3690}
.modal_x:hover{color: #3D4A36;}
.map-modal_content h2{font-size: 1.5vw; width: 90%; text-transform: uppercase; font-weight: 700; color: #3D4A36; margin-bottom: 1vw;}
.region_info{list-style: none; margin-right: 0px; padding: 0px;}
.region_info li{padding:1vw 0; border-bottom: 2px solid #3D4A3690; font-size: 1vw; font-weight: 500; color: black;}
.region_info li strong{margin-right: 0.6vw;}
.region_info a{color: black !important; font-weight: 500; font-size: 1vw; text-decoration: none; }
.region_info a:hover{color: #3D4A36 !important;}
.modal-content{border-radius: 1.35vw !important;}
.mapRT{width: 100%; padding-left: 20vw; height: 100%; overflow: hidden;background-color: #fbeedc; position: relative;}
.mapRT::before{position: absolute;background-color: #fbeedc; content: ''; top:0; right:0; width: 3%; height: 100%; z-index: 4; }

/* .mapRT_item{transform: scale(1.28, 1.28);} */
.poligons{ width: calc(31vw - 1.86vw);position: absolute; top:0; left:0vw;height: 100%; z-index: 322;overflow-y: auto; overflow-x: hidden; }
.poligons_item{width: 100%; height: 4vw; margin-top: 3px; color: #3D4A36; text-transform: uppercase; font-weight: 500; font-size: 1vw; background-color: #fbeedc; display: flex; align-items: center; padding: 0.5vw 2vw;}
.poligons_item:nth-child(1){margin-top: 0px;}
.poligons_item:hover{background-color: #B26C08; color: white;}
.ymaps-2-1-79-gotoymaps__text-container, .ymaps-2-1-79-copyright__content{display: none !important;}



@media(max-width:1024px){
   .mapRT{display: none !important;}
.poligons{width: 100%; position: relative;}
.poligons_item{width: 100% !important; font-size: 2vw; height: 7vw; display: flex; justify-content: center;}
.mapR:after{width: 100%;}
.mapR::before{display: none;}
.modal_x{font-size: 5vw;}
.region_name{font-size: 3vw !important; margin-top: 2vw !important; margin-bottom: 2vw !important;}
.region_info li{font-size: 2.3vw; padding:2vw 0}
.region_info a{font-size: 2.3vw;}
.region_info li strong{position: relative; top: 0.5vw;}
}
@media(max-width:760px){
    .poligons_item{width: 100% !important; font-size: 3vw; height: 12vw; text-align: center; display: flex; justify-content: center;}
    .modal_x{font-size: 7vw;}
.region_name{font-size: 4vw !important; margin-top: 4vw !important; margin-bottom: 2vw !important;}
.region_info li{font-size: 3.5vw; padding:5vw 2vw}
.region_info a{font-size: 3.5vw;}
.region_info li strong{position: relative; top: 0.5vw;}
}
