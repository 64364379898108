@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');*{  font-family: 'Montserrat', sans-serif;   transition: 0.3s !important;}
.navbar_dash{width: 100vw;  padding: 0vw 7vw !important; background-color: rgba(255, 255, 255, 0.7); backdrop-filter: blur(10px); display: flex; align-items: center; height: 5vw; position: sticky !important; margin-top: 2.1vw;  top: 0vw !important; left: 0; z-index: 2344;}
.navbar_dash nav{background-color: transparent !important; width: 100%; height: 100%;}
.navbar_brand{display: flex; align-items: center;}
.navbar_brand img{width:4.3vw}
.navbar_brand p{font-weight: 800;  font-size: 1.04vw;  margin-left: 0.7vw;  line-height: 1.25vw;  color: #B26C08;}
.navbar_brand span{color: #3D4A36;}
#basic-navbar-nav{ width: 100%;}
.me-auto{margin-left: auto !important; margin-right: 0 !important;}
.til{ display: flex; margin-left: 1vw;}
.til img{height: 1vw; width: 1.7vw; position: relative; left: 0.2vw;}
.link{font-weight: 500 !important;    font-size: 1.04vw !important;  transition: 0.1s !important;   white-space: nowrap; width: 7vw;  line-height: 1.25vw;      margin-left: 1.7vw;    text-align: center;    color: #3D4A36 !important;}
.faqLink{width: 3vw;}
.aboutLink{width: 8.4vw;}
.link:hover{font-weight: 700 !important;}
.active_link{font-weight: 700 !important;}
.til p{font-weight: 700; text-transform: uppercase;    font-size: 1.04vw;    line-height: 1.25vw;    text-align: center;    margin-left: 0.3vw;    color: #3D4A36;}

.main{width: 100vw; height: 42.6vw; 
  display: flex; 
  padding-top: 6vw; background-image: url('./assets/main.svg');background-repeat: no-repeat; background-size:100% auto; background-position: center center;}
.main_text{width: 45%; display: flex; height: 60%; display: flex; align-items: center; justify-content: flex-end;} 
.main_text h1{font-weight: 800;
  font-size: 2.6vw;
  line-height: 2.7vw;
  text-align: center;
  
  color: #B26C08;}
  .main_text span{color: #3D4A36;}
.main_img{width: 55%; height: 100%; background-image: url('./assets/main_mg.svg'); background-repeat: no-repeat; background-position: center center; background-size: 42.4vw auto;}
::-webkit-scrollbar {
 display: none;
}
.about{width: 100%; padding: 2vw 16.3vw; display: flex;}
.about_img{display: flex; justify-content: center; align-items: center; flex-direction: column;}
.about_img img{width: 12.4vw;}
.about_img p{width: 20.1vw;margin-top: 1.56vw;font-weight: 700;
  font-size: 0.83vw;
  line-height: 1vw;
  text-align: center;
  color: #414D36;}
.about_text{padding-left: 3.4vw;}
.about_text h1{font-weight: 700;
  font-size: 1.82vw;
  line-height:2.23vw;
  text-align: center;
  text-transform: uppercase;

  color: #3D4A36;}
.about_text p{font-weight: 400;
  font-size: 0.78vw;
  line-height: 1vw;
  text-align: justify;
  margin-bottom: 1vw;
  color: #000000;}
.btnGreen{width:13.4vw; height: 2.23vw; background: #6A7334;margin-top: 2vw;
  border-radius: 0.36vw; display: flex; align-items: center; justify-content: center; text-decoration: none;font-weight: 700;
  font-size: 0.78vw;margin-left: auto; margin-right: 0; border: 2px solid #6A7334; transition: 0s !important;
  color: #FFFFFF;}
  .btnGreen .btn_img{width:1.04vw; height: 1.04vw; background-image: url('./assets/pdf.svg'); background-size: 100% 100%; background-repeat: no-repeat; 
    background-position: center center;
    margin-right: 0.57vw;}
    .gallery .btnGreen{margin-right: 3vw;}
.btnGreen:hover{background: transparent;color: #6A7334; }
.btnGreen:hover .btn_img{background-image: url('./assets/pdf_hover.svg');}
.partners{margin-top: 4vw; padding: 0 3.72vw;}
.title{font-weight: 700;
  font-size: 1.82vw;
  line-height: 2.24vw;
  text-align: center;
  text-transform: uppercase;
  color: #3D4A36; margin-bottom: 4vw;}
.partners_box{display: flex;}
.partners_item{display: flex; align-items: center; padding: 0 3.72vw;}
.partners_item img{height: 8vw; margin-right: 1.4vw;}
.partners_item a{font-weight: 700;
  font-size: 1.1vw;
  line-height: 1.56vw;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #3D4A36;}
  .partners_item a:hover{color: #B26C08;}
  .stat{width: 100%; height: 100vh; background-image: url('./assets/stat.svg'); background-repeat: no-repeat; background-size: 100% auto; background-position:  center 3vw; margin-top: 3vw;}
  .stat_box{width: 65vw; margin-left: 15vw; margin-top: 10vw; display: flex; align-items: flex-start; justify-content: space-between;}
 .stat_item{display: flex; align-items: center;}
  .stat_item h1{font-weight: 700;
  text-align: center;  font-size: 2.34vw;
    line-height: 2.5vw;color: #3D4A36;}
    .stat_item p{font-weight: 700;
    text-align: center;  font-size: 2.34vw;
    line-height: 2.5vw;color: #B26C08;}
      .tir{width: 100vw; height: 100vh; background-image: url('./assets/tir_back.svg'); background-size: 105 center; position: relative; background-position: center -50%; background-repeat: no-repeat;}

.play_box{width: 100%;  height: 100%;
  flex-direction: column; 
  position: absolute; top: 0; left: 0; z-index: 12; display: flex; align-items: center; justify-content: center;}
  .play_box_back{width: 100%;  height: 100%; position: absolute; z-index: 1 !important; top: 0; left: 0; background-image: url('./assets/animals.svg'); background-size: 105% auto;background-position: center 5%; background-repeat: no-repeat; }
.gaming{width: 100%;  height: 100%;  position: relative; top: 0; left:0;}
.gaming .img{ position: absolute; border-radius: 50%; background-repeat: no-repeat; background-size: 100% auto; background-position: center center; 
  user-select: none !important; transition-timing-function: ease-out !important;}
.img_animal{ transition: 6.5s !important; }
.img_bird{width: 7vw; height: 7vw; transition: 5.5s !important; z-index: 15 !important;}
.img_quyon{ transition: 0.5s !important;}
/* .img div{ background-color: red;} */
.img_quyon div{margin-top: 40%; width: 80%; height: 45%; margin-left: 10%;}
.img_animal div{margin-top: 40%; width: 50%; height: 40%; margin-left: 30%; border-radius: 30%;}
.img_bird div{margin-top: 50%; width: 100%; transform: rotateZ(-15deg); height: 35%; margin-left: 0%; border-radius: 30%;}
.grass{width: 105%; position: absolute; height: 15vw; background-size: 100% auto; background-repeat: no-repeat; background-position: bottom center;}
.grass_3{bottom: 5%; left:0; z-index: 10; background-image: url(./assets/grass_3.svg); }
.grass_2{bottom: 23%; left:0; z-index: 5; background-image: url(./assets/grass_2.svg);}
.grass_1{bottom: 38%; left:0; z-index: 1; background-image: url(./assets/grass_1.svg);}
.play_btn{width: 12.6vw; height: 12.6vw; border-radius: 50%; display: flex; position: relative;  align-items: center; justify-content: center; border: none; outline: none;background: linear-gradient(180deg, #6A7334 0%, #E5C584 100%);}
.play_btn img{width: 5.7vw; position: relative; left:0.7vw}
.play_box h1, .gaming_head h1{font-weight: 700;
  font-size: 2.34vw;
  margin-top: 2.34vw;
  line-height: 2.6vw;
  text-align: center;
  text-transform: uppercase;
  color: #3D4A36;
  }
 
  .gaming_head{width: 100vw; height: 3vw; display: flex; position: absolute; top: 2vw;left:0; justify-content: center; position: relative; align-items: center; padding:0 5vw; }
  .shots{position: absolute; right: 5vw; }
  .gaming_head h1{margin-top: 0;}
  .gaming_head img{height: 3vw;}
  .result_game{width: 100%; position: absolute; top: 0; z-index: -1;  left: 0; transform: scale(0, 0);  height: 100%; display: flex; align-items: center; justify-content: center;}
  .result_game_open{transform: scale(1,1); z-index: 10;}
  .result_game_box{width: 51vw; padding: 3vw;background: rgba(108, 117, 54, 0.52);
    backdrop-filter: blur(7.5px);border-radius: 2.5vw; position: relative;}
    .replay{position: absolute; right:3vw; top:2.6vw; z-index: 34;}
    .result_game_box h1{font-weight: 700;
      font-size: 1.82vw;
      line-height: 2vw;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;}
    .result_game_imgs{display: flex; justify-content: space-around; align-items: center; margin-top: 5.5vw;}
    .game_item{height: 10vw; display: flex; flex-direction: column; justify-content: flex-end; align-items: center;}
    .game_item p{font-weight: 700;
      font-size: 2.08vw;
      line-height: 2.2vw;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF; margin-top: 1vw;}
    .game_item img{width: 8.7vw;}
    .game_item:nth-child(3) img{width: 7.4vw;}
    .gallery{width: 100vw; height: 120vh; background-image:url(./assets/gallery_back.svg); background-size: 100% auto; background-position: top center; background-repeat: no-repeat; padding: 4vw; position: relative !important; display: flex; }
    .gallery_modal{position: absolute; top: 0px; transform: scale(0, 0); width: 100vw; height: 100%; left:0px; display: flex; align-items: center; justify-content: center;  z-index: 344;}
   .modal_back_gallery{position: absolute;top: 0px; width: 100vw; height: 100%; left:0px;background-color: #414d36b2; backdrop-filter: blur(10px);}
    .gallery_carousel{width:70%; height: auto; overflow: hidden;}
    .open_gallery{transform: scale(1,1);}
    .item_gallery{width: 100%; border-radius: 2vw; padding: 0 0%; height: 60vh; display: flex; position: relative; align-items: center; justify-content: center; overflow: hidden;}
    .item_gallery p{position: absolute; top: 2vw; left:0px;  width: 100%; height:3vw; background-color: #414d36b2; display: flex; align-items: center; justify-content: center; color: white; font-weight: 500; font-size: 1.2vw;}
    .gallery_carousel img{width: 100%;height: auto;}
    .gallery_imgs{position: relative; width: 60%; height: 100%; display: flex; align-items: center; justify-content: center;}
    .gallery_text{width: 40%;}
    .gallery_left{position: absolute; left:3vw; width: 20.3vw; top: 6vw; z-index: 2;}
    .gallery_center{position: absolute; left:12vw; width: 20.3vw; top: 14vw; z-index: 3;}
    .gallery_right{position: absolute; left:20vw; width: 20.3vw; top: 7vw; z-index: 1;}
  .gallery_imgs div{ width: 40vw; height: 30vw; margin-left: -10vw; margin-top: -9vw;}
  .gallery_imgs div:hover .gallery_right{left: 23vw; transform: rotate(5deg);}
  .gallery_imgs div:hover .gallery_left{left: 0vw; transform: rotate(-5deg);}
  .gallery_imgs div:hover .gallery_center{top: 16vw; transform: rotate(0deg);}
    .gallery .title{margin-bottom: 2vw;}
    .gallery_text p{font-weight: 400;
      font-size: 0.78vw;
      line-height: 1vw;
      text-align: justify;
      
      color: #000000;
      }
    .btngallery .btn_img{background-image: url(./assets/eye.svg);}
    .btngallery:hover .btn_img{background-image: url(./assets/eye_hover.svg);}
    .footer{width: 100vw; height: auto; padding: 3.64vw; padding-left: 22%; background-image: url(./assets/footer.svg); background-size: auto 100%; background-position: bottom -2.3vw left 0vw; background-repeat: no-repeat;  background-color: #3D4A36; display: flex; justify-content: space-between;  border-top: 8px solid #E2BA65; margin-top: 2vw;}
   .footer_nav .link{color: white !important; text-align: left;font-weight: 400 !important;
    font-size: 1.04vw; width: auto !important;
    line-height: 1.15vw;}
   .footer h1{font-weight: 800;
    font-size: 1.04vw; 
    line-height: 1.2vw;
    margin-bottom: 1.15vw;
    color: #FFFFFF;}
   .footer_nav{display: flex; flex-direction: column; margin-right: 1.04vw;}
   .footer_center{display: flex; flex-direction: column; justify-content: flex-start !important; width: 40%;}
   .footer_center a{display: flex; font-weight: 700;
    font-size: 1.66vw;
    line-height: 2vw; text-decoration: none;
    text-align: right;
    
    color: #FFFFFF;}
   .footer_center a img{width: 2.08vw; margin-right: 1vw; margin-bottom: 1.2vw;}
   .footer_center a:hover{color: white;}
   .footer_center p{font-weight: 400 !important;
    font-size: 1.04vw; 
    line-height: 1.15vw;
    
    color: #FFFFFF;}
    .footer_right{display: flex; position: relative; flex-direction: column; align-items: flex-end;}
    .footer_brand{display: flex; align-items: center; margin-bottom: 1.1vw;}
    .footer_brand .brand_link{text-align: right; margin-right: 0.5vw;font-weight: 800;
      font-size: 1.04vw; 
      line-height: 1.15vw;
      text-align: right;
      text-decoration: none;
      color: #FFFFFF;
      }
      .brand_link:hover{color: white !important;}
      .brand_link:active{color: white !important;}
    .footer_links{display: flex; padding-right: 6vw; margin-bottom: 1.1vw;}
    .footer_links a img{height: 1.7vw; margin-right: 1vw;}
.vitc{position: absolute; bottom: -1vw; right: 0px; white-space: nowrap;font-weight: 700;
  font-size: 1.04vw; 
      line-height: 1.15vw;
  text-align: right;
  text-decoration: none;
  color:#E2BA65;}
.vitc:hover{color: white;}

.faq{margin-top: 3vw;  padding: 0 3.72vw; }
.faq_box{display: flex; border-radius: 2.34vw;  position: relative;}
.faq_img{position: absolute; right: 1vw; top:-6vw; width:18.22vw}
.faq_questions{display: flex; flex-direction: column; width: 31vw;}
.faq_answer{width:69vw; height: 35vw; padding:5vw; background: #F5F6F2; font-weight: 400;
  font-size: 1.05vw;
  line-height: 1.7vw;
  display: flex;
  align-items: center;
  text-align: justify;
  border-radius:  0 2.34vw 2.34vw 0;
  color: #000000;}
  .faq_answer div{width: 100%; height: 100%; overflow-y: auto;}
.question_item{width:100%; height: 5vw; display: flex; align-items: center; background: #F5F6F2;
  border: 2px solid #FFFFFF; padding:1.82vw; font-weight: 400;
  font-size: 1.05vw;
  line-height: 1.5vw;
  display: flex;
  align-items: center; justify-content: space-between;
  color: #3D4A36;}
  .question_item img{width: 3vw; opacity: 0;}
  .question_item:nth-child(1){border-radius: 2.34vw 0 0 0;}
  .question_item:nth-child(7){border-radius: 0 0 0 2.34vw;}
.question_item:hover{background: #E1E3D6;}
.question_active{background: #E1E3D6;}
.question_active img{opacity: 1;}
.stat_item .btnGreen{margin-right: auto !important; margin-left: 3vw; margin-top: 0px;}

.membersLoader{position: absolute; top: 5%; left: 0%; width: 100%; height: 95%; z-index: 323; display: flex; align-items: center; justify-content: center;
background-color: #ffffff20; backdrop-filter: blur(10px);
}
.ant-modal-title{font-size: 1.4vw !important; color: #3D4A36 !important; font-weight: 700 !important; text-transform: uppercase;}
.members p{font-size: 1.1vw; font-weight: 500; color: #414d36b2; margin-bottom: 0.5vw; margin-top: 0.5vw;}
.members input{font-size: 1vw !important;  width: 100%; padding: 0.4vw; border-radius: 0.35vw; outline: none; border: 2px solid #414d36b2; 
  color: #3D4A36; font-weight: 500;} 
.membersDiv{min-height: 55vh; position: relative; display: flex; align-items: flex-start; justify-content: center; overflow-y: auto;}
.emptymembers{font-weight: 700; font-size: 1.2vw;  color: #414d36b2;}
table, td, th, tr{border: 2px solid #414d36b2 !important; }
.membersDiv table{width: 100%; border-radius: 0.35vw; margin-top: 2vw;}
.membersDiv table th{padding: 0.3vw; font-size: 1vw; color: #3D4A36;}
.membersDiv table td{padding: 0.3vw; font-size: 1vw; color: #3D4A36; font-weight: 500;}
.ant-modal{margin-top: 4vw !important;}
.membersInput{position: relative;padding-top: 1vw;}
.membersInput button{position: absolute; height: calc(100% - 1vw); right: 0px; bottom:0px}
.news_card{width: 100%; cursor: pointer; overflow: hidden; border-radius: 7%; text-decoration: none;  background-color: #7D806D; height: 22vw; padding: 0.45vw; display: flex; flex-direction: column; flex-wrap: wrap;}
.news_card_img{height: 65%; display: flex; border-radius: 7%; overflow: hidden; width: 100%; justify-content: center; align-items: center;}
.news_card img{height: 100%; transform: scale(1, 1) translate(0, 0); width:auto; min-width: 100%;}
.news_card h1{font-size: 1vw; color: white; height: 12%; padding: 0 0.5vw; overflow-y: hidden; margin-top: 0.5vw;}
.news_card p{font-size: 0.8vw; color: white; margin-top: 2vw; color: #E5C584;  padding: 0 0.5vw;}
.news_card p span{font-size: 1vw; margin-right: 0.25vw; position: relative; top:-2px}
.news_card:hover{background-color: #6A7334;}
.news_card:hover .news_card_img{transform: scale(1.1, 1.1) translate(0,-5%); border-radius: 0;}
.addNum{position: absolute; z-index: 8766; font-size: 35px; transition: 1.5s !important; font-weight: 700; color:#3D4A36; transform: translate(0, 0); opacity: 1;}
.dis_addNum{transform: translate(0, -130px); opacity: 0;}
@keyframes add_num {
  0%{opacity: 1; transform: translate(0, 0);}
  100%{opacity: 0; transform: translate(-200, 0);}
}
@media(max-width:1024px){
  .news_card{height: 30vw; padding: 0.6vw; display: flex; flex-direction: column; flex-wrap: wrap;}
.news_card h1{font-size: 1.5vw; height: 12.5%; padding: 0 1.5vw;margin-top: 1.5vw;}
.news_card p{font-size: 1.2vw; padding: 0 1.5vw;margin-top: 2.1vw;}
.news_card p span{font-size: 1.5vw; top:-1px}
  .link{font-size: 2vw !important; font-weight: 700 !important; text-align: center !important; width: 100% !important; margin-bottom: 2vw}
  .active_link{font-weight: 900 !important;}
  .navbar_dash{height: 10vw;}
.navbar_brand img{width: 9vw;}
.navbar_brand p{font-size: 2vw; line-height: 2.3vw;}
.ant-modal-title{font-size: 3.5vw !important;}
.members input{font-size: 2vw !important;padding: 1vw;}
.membersDiv table th{padding: 1vw; font-size: 2vw;}
.membersDiv table td{padding: 1vw; font-size: 2vw;}
.navbar_brand span{font-size: 2vw !important;  line-height: 2.3vw;}
.til{width: 100%; height: 3vw;position: relative; top:-4vw; left:-1vw; display: flex; align-items: center; justify-content: center;}
.til p{font-size: 2.5vw; margin-left: 2vw;}
.main_text h1{font-size: 3.5vw; line-height: 3.5vw;}
.about{padding: 4vw;}
.tir .title, .about .title, .title{font-size:3vw; margin-bottom: 4vw !important;}
.btnGreen{width: 25vw; height: 5vw; margin-top: 4vw;}
.stat_item button{width: 32vw;}
.btn_img{width: 2.5vw !important;height: 2.5vw !important; margin-right: 1.7vw !important;}
.btnGreen span{font-size: 1.9vw;}
.stat{height: 33vh;}
.gallery{height: 43vh;}
.item_gallery{height: 33vh;}
.item_gallery p{height: 5vw; font-size: 2vw}
.about_img p{font-size: 2vw; line-height: 2.3vw;}
.partners_item a, .question_item, .faq_answer, .footer_center h1,.vitc,.footer_brand a p,  .footer_center p, .gallery_text p, .about_text p{font-size: 1.7vw; line-height: 2.2vw;}
.partners_item{padding: 0 2vw;}
.partners_item img{width: 10vw; height: 10vw;}
.stat_item h1, .stat_item p{font-size: 3.5vw;}
.stat_item p{margin-top: 3vw;}
.tir{height: 35vh;}
.stat_item{flex-direction: column;}
.stat_box{width: 90%; margin-left: 5%;}
.replay img{width: 4vw; top: 1.7vw;}
.me-auto{display: flex;align-items: center;  background-color: rgba(255, 255, 255, 0.7); backdrop-filter: blur(10px) !important; height: 30vh; border-radius: 8vw;}
.footer{flex-direction: column; align-items: flex-end; background-size: auto 90%; background-position: bottom -6vw left 0;}
.footer_nav{display: none !important;}
.footer_center{align-items: flex-end; width: 60%; margin-top: 3vw;}
.footer_center h1{text-align: right;}
.footer_center a img{width: 4vw;}
.footer_center a{font-size: 3vw; line-height: 2.4vw; margin-top: 3vw; align-items: center; margin-bottom: 2vw;}
.footer_brand{margin-top: 3vw;}
.footer_links{margin-top: 3vw; padding-right: 10vw; margin-bottom: 4vw;}
.footer_links a img{height: 5vw; margin-right: 3vw;}
.question_item{height: 10vw; padding: 1vw !important;}
.question_item img{width: 4vw;}
.faq_answer{height: 70vw; padding: 8vw 5vw;}
.faq_img{top: -5vw; width: 22vw;}
.faq{margin-top: 8vw;}
.stat_item .btnGreen{ margin-top: 3vw;}
}
@media(max-width:730px){
  .news_card{height: 60vw; padding: 2vw; display: flex; flex-direction: column; flex-wrap: wrap;}
  .news_card h1{font-size: 3vw; height: 12.5%; padding: 0 2.5vw;margin-top: 2.5vw;}
  .news_card p{font-size: 2.5vw; padding: 0 2.5vw;margin-top: 5vw;}
  .news_card p span{font-size: 3vw; top:-2px}
.main{flex-direction: column; height: 60vw; align-items: center;}
.main_text{width:80%; display: flex; justify-content: center;}
.main h1{font-size: 5vw; line-height: 6vw;}
.navbar_dash{height: 18vw;}
.navbar_brand img{width: 12vw; height: 12vw;}
.brand_text p{font-size: 3vw; line-height: 3.5vw;}
.brand_text span{font-size: 3vw !important; line-height: 3.5vw !important;} 
.title{font-size: 4vw !important;}
.ant-modal-title{font-size: 5vw !important;}
.members input{font-size: 3vw !important;padding: 2vw;}
.membersDiv table th{padding: 2vw; font-size: 3vw;}
.membersDiv table td{padding: 2vw; font-size: 3vw;}

.link{font-size: 3vw !important; font-weight: 700 !important; text-align: center !important; width: 100% !important; margin-bottom: 4vw}
.active_link{font-weight: 900 !important;}
.navbar-toggler{outline: none; box-shadow: none !important; transform: scale(0.8, 0.8);}
.til{top:-5vw}
.til p{font-size: 3.5vw;}
.main_text h1{font-size: 3.5vw; line-height: 3.5vw;}
.about{padding: 4vw; flex-direction: column;}
.about_img{display: none;}
.about_text{padding-left: 0;}
.btnGreen{width: 35vw; height: 8vw; margin-top: 6vw;}
.members .btnGreen{width: 25vw;}
.stat_item button{width: 40vw;}
.btn_img{width: 3.5vw !important;height: 3.5vw !important; margin-right: 1.7vw !important;}
.btnGreen span{font-size: 2.6vw;}
.stat{height: 35vh;}
.gallery{height: 43vh;}
.item_gallery{height: 21vh;}
.item_gallery p{height: 6vw; font-size: 2.5vw}
.about_img p{font-size: 2vw; line-height: 2.3vw;}
.partners_box{flex-direction: column; align-items: center;}
.partners_item a, .question_item, .faq_answer, .footer_center h1,.vitc,.footer_brand a p,  .footer_center p, .gallery_text p, .about_text p{font-size: 2.5vw; line-height: 3vw;}
.partners_item{padding: 0 2vw; flex-direction: column; width: 60%;}
.partners_item img{width: 20vw; height: 20vw; margin-bottom: 3vw; margin-top: 3vw;}

.stat_item h1, .stat_item p{font-size: 4vw;}
.stat_item p{margin-top: 5vw;}
.tir{height: 35vh;}
.replay img{width: 4vw; top: 1.7vw;}
.me-auto{display: flex;align-items: center;  background-color: rgba(255, 255, 255, 0.9); height: 42vh; backdrop-filter: blur(10px) !important; border-radius: 8vw; padding-top: 4vw;}
.footer{flex-direction: column; align-items: flex-end; background-size: auto 60%; background-position: bottom -6vw left 0;}
.footer_nav{display: none !important;}
.footer_center{align-items: flex-end; width: 60%; margin-top: 3vw;}
.footer_center h1{text-align: right;}
.footer_center a img{width: 4vw;}
.footer_center a{font-size: 3vw; line-height: 2.4vw; margin-top: 3vw; align-items: center; margin-bottom: 2vw;}
.footer_brand{margin-top: 3vw;}
.footer_links{margin-top: 3vw; padding-right: 10vw; margin-bottom: 4vw;}
.footer_links a img{height: 5vw; margin-right: 3vw;}
.question_item{height: 15vw; padding: 2vw !important;}
.question_item img{width: 4vw;}
.faq_answer{height: 30vh; width: 100%; padding: 8vw 5vw; border-radius: 0 0 2.34vw 2.34vw;}
.faq_img{display: none;}
.faq{margin-top: 8vw;}
.faq_questions{width: 100%;}
.question_item:nth-child(1){border-radius: 2.34vw 2.34vw 0 0;}
.question_item:nth-child(7){border-radius: 0 0 0 0;}
.faq_box{flex-direction: column;}


}