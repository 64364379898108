.fotogalereya {
    padding: 70px 0px;
    min-height: 100vh;
}

.foto {
    display: flex;
    flex-direction: row;
    transition: 0.3s;
}
.foto:hover .foto_image {
    filter: grayscale(1);
}
.foto_column {
    display: flex;
    flex-direction: column;
    width: 25%;
}
.foto_link {
    height: auto;
    margin-top: 0px;
    overflow: hidden;
}
.foto_link:hover .foto_image {
    filter: grayscale(0);
    transform: scale(1.1, 1.1);
}
.foto_link:hover .foto_caption {
    opacity: 1;
}
.foto_thumb {
    position: relative;
    height: 100% !important;
}
.ant-image-mask:hover {
    opacity: 0 !important;
}
.foto_image {
    display: block;
    height: 100% !important;
    transition: 0.3s;
}

.foto_caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px 15px 15px;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    color: white;
    opacity: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(255, 255, 255, 0) 100%);
    transition: 0.3s;
}
.ant-image-preview-operations-wrapper{z-index: 6000 !important;}
.ant-image-preview-mask{z-index: 2600 !important;}
.ant-image-preview-wrap{ z-index:4000 !important;}
@media (max-width: 768px) {
    .foto {
        flex-direction: column !important;
   }
    .foto_column {
        width: 100% !important;
   }
}
@media (max-width: 1024px) {
    .foto{flex-wrap: wrap;}
    .foto_column{width: 50%;}

}