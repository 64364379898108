body{margin: 0px !important; width: 100%; position: absolute; top: 0px; right: 0px; height: 100vh; overflow-x: hidden !important;}
.big_boss{width: 100vw; min-height: 100vh; height:auto; }
p, h1,h2,h3,h4,h5,h6{margin: 0px;}
*, a{transition: 0.3s; box-sizing: border-box; 
  cursor: url(./assets/cursor.svg) 25 25, auto !important;  user-select: none;
  
}
*:hover{ cursor: url(./assets/cursor.svg) 25 25, auto !important;}

.box{width: 50px; height: 50px; background-color:red; display: flex; align-items: center; justify-content: center;}
.box:hover{color: red; }
*, a:active{ cursor: url(./assets/cursor_shot.svg) 30 30, auto !important;}
.shot_gun{ position: absolute; opacity: 0; width: 2vw; height: auto; top: 0; left: 0;}
a:hover,.navbar-toggler:hover,.navbar-toggler *:hover, .newsLink:hover,.footer_contacts a:hover, .slick-dots *:hover, button *:hover,button:hover, .play_btn:hover, .pag_item:hover,.footer_center a *:hover, .question_item:hover,.question_item *:hover, .play_btn img:hover, .navbar_brand:hover,.navbar_brand *:hover, .footer_brand:hover,.footer_brand *:hover, .footer_links *:hover, .footer_contacts p:hover, .footer_contacts img:hover, .til p:hover{ cursor: url(./assets/cursor_shot.svg) 30 30, auto !important;}
.id_loc{position: relative; top:-4vw;}
.tooltip, .tooltip *{transition: 0s !important; z-index: 6754 !important;}

.tooltip-inner{ max-width: 40vw !important; background-color: #6a7334dc !important; backdrop-filter: blur(5px); font-size: 1vw;}
.tooltip-arrow::before{border-bottom-color: #6a7334dc !important;}
@media(max-width:1024px){
  .shot_gun{width:6vw; height: auto; margin-top: -0.5vw; margin-left: -0.5vw;}
  .tooltip{display: none !important;}
 }
 @media(max-width:730px){
  .shot_gun{width:10vw; height: auto; margin-top: -0vw; margin-left: -0vw;}
 
 }