.news {
  padding: 70px 0 30px 0;
  
}
.news .allnewss {
  text-decoration: none;
  text-align: right;
  float: right;
  padding: 10px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 18px;
  background-color: #6A7334;
  border-radius: 10px;
  color: white;
  transition: 0.3;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.news .allnewss:hover {
  background-color: #fff;
  color: #6A7334;
}
.news .ant-row{justify-content: center;}
@media (max-width: 1090px) {
  .news .allnewss {
    font-size: 18px;
 }
}
.news_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 15px;
  width: 100%;
}
@media (max-width: 1125px) {
  .news_list {
    grid-template-columns: repeat(2, 1fr);
 }
}
@media (max-width: 660px) {
  .news_list {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
    margin: 0 auto;
 }
}
@media (max-width: 522px) {
  .news_list {
    width: 100%;
 }
}
a{text-decoration: none;}
.news_list-item {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  max-width: 515px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
  background-color: #fff;
}
@media (max-width: 1090px) {
  .news_list-item {
    width: auto;
    flex: 1 1;
    min-width: 300px;
 }
}
.news_list-item_content {
  padding: 20px;
}
.news_list-item_img {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.news_list-item_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
.news_list-item-link {
  letter-spacing: 1px;
  color: #333;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
}
.news_list-item_date {
  font-weight: 400;
  margin-top: 20px;
}
.news_list-item:hover {
  transform: scale(0.95);
}
.news_list-item:hover .news_list-item-link {
  color: #6A7334;
}
.news_list-item:hover .news_list-item_img img {
  transform: scale(1.05);
}
.all-news .ant-row, .news .ant-row {
  width: 100% !important;
}
.all-news .ant-col, .news .ant-col {
  width: 100% !important;
}


:root {
    --color: #ffffff;
    --transition-time: 0.5s;
  }


a {
  color: inherit;
}

.cards-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
flex-wrap: wrap;
 
  margin: 0 auto;
  width: 100%;
}
.news_row{min-height: 33vw ; overflow-y: hidden;}
.card {
  font-family: 'Heebo';
  --bg-filter-opacity: 0.3;
  background-image: linear-gradient(rgba(0,0,0,var(--bg-filter-opacity)),rgba(0,0,0,var(--bg-filter-opacity))), var(--bg-img);
  height: 15vw;
  width:100%;
  font-size: 1.5em;
  color: white;
  border-radius: 5px;
  

  display: flex;
  align-items: flex-end;
  position: relative;
  background-size: cover;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: all, var(--transition-time);

  overflow: hidden;
  
  text-decoration: none;
}

.card:hover {
  transform: rotate(0);
}

.card h1 {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}
.visit svg{position: relative; top: 3px;}
.card p {
  font-size: 0.75em;
  font-family: 'Roboto';
  text-transform: uppercase;
  margin-top: 2em !important;position: relative;
  top:15px;
  width:calc(100% - 55px);
  line-height: 1.5em;
}

.card .tags {
  display: flex;
  height:47px; 
 background-color: transparent !important;
  box-shadow: 0px 0px 0px transparent !important;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom:20px;
  justify-content: flex-end;
}
.card .tags .tag {
  font-size: 0.75em;
 background-color: transparent !important;
  border-radius: 50%;
  width:40px;
  box-shadow: 0px 0px 0px transparent !important;

  height:40px;
  display: flex;
  padding:0px !important;
  align-items: center;
  justify-content: center;


  transition: all, var(--transition-time);
}

.icon{margin-top:-10px; }
.card:hover .tags .tag {

  color: white;
}
.news_list-item1{color:rgba(255,255,255,0.5);}
.card:hover .news_list-item1{color:white}
.card .date {
  position: absolute;
  top: 7px;
  left:7px;
  font-size: 0.9em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}
.card .visit {
  position: absolute;
  right: 7px;
  top:7px;
  display: flex; align-items: center;
  font-size: 0.9em;
  padding: 1em;
  line-height: 1em;
  opacity: .8;
}

.card:before, .card:after {
  content: '';
  transform: scale(0);
  transform-origin: top left;
  border-radius: 50%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  
  transition: all, var(--transition-time);
  transition-timing-function: ease-in-out;
}

.card:before {
  background: rgb(46, 46, 46, 0.2);
  width: 250%;
  height: 250%;
}

.card:after {
  background: rgba(16, 16, 16, 0.2);
  width: 200%;
  height: 200%;
}

.card:hover {
  color: var(--color);
}

.card:hover:before, .card:hover:after {
  transform: scale(1);
}

.card-grid-space .num {
  font-size: 3em;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
}

.info {
  font-size: 1.2em;
  display: flex;
  padding: 1em 3em;
  height: 3em;
}

.info img {
  height: 3em;
  margin-right: 0.5em;
}

.card-grid-space{padding:15px !important; flex:33.33% ;}

/* MEDIA QUERIES */
@media screen and (max-width: 1285px) {
  .cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .card-grid-space{flex:50%}
}

@media screen and (max-width: 900px) {
  .cards-wrapper {
    grid-template-columns: 1fr;
  }
  .info {
    justify-content: center;
  }
  .card-grid-space .num {
   margin-left: 0;
   text-align: center;
 
  }
  .card-grid-space{  flex:100% !important;}
}

@media screen and (max-width: 500px) {
  .cards-wrapper {
    padding: 4rem 2rem;
  }
  .card {
    max-width: calc(100vw - 4rem);
  }
}

@media screen and (max-width: 450px) {
  .info {
    display: block;
    text-align: center;
  }
  .info h1 {
    margin: 0;
  }
}
.blackPar{width:100%; display: flex;
    align-items: flex-end;
    
    position: relative; height:16em; overflow: hidden; 
transition: 0.3s; padding: 1em;}


.card p{ height:48px; font-weight: 500; color:white; letter-spacing: 0.5px; overflow-y: hidden;  display: flex; align-items: flex-start; font-size: 18px;}
.allnewss{
  text-decoration: none;
  text-align: right;
  float: right;
  margin-right: 15px;
  padding:10px 40px;
  margin-top: 15px;
  margin-bottom: 40px;
  font-size: 18px;
  background-color: #6A7334;
  border-radius: 5px !important;
  color:white;
  transition: 0.3;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
 
 
 
}
.allnewss:hover{
  background-color: #fff;
  color: #6A7334;
}

@media (max-width: 1090px) {
  .allnewss{font-size: 18px;}
}
.news{padding:70px 0 30px 0;
}
.black{position: absolute; bottom:0px; left:0px; width:100%; height:90px; border-radius: 5px; background-color: rgba(0, 0, 0, 0.419);}
.paginations{display: flex; align-items: center; justify-content: center; margin-top: 40px;}
.pag_item{width: 50px; height: 50px; display: flex; align-items: center; font-size: 20px; font-weight: 500; justify-content: center; background-color: #B26C08; color: white; margin: 5px; border-radius: 50%;}
.pag_item:hover, .active_page{background-color: #3D4A36;}
.home-header {
  height: 100%;
}
.home-header .swiper {
  width: 100%;
  height: 100%;
}
.home-header .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 95vh;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .home-header .swiper-slide {
    height: 500px;
 }
}
.home-header .swiper-slide_body {
  flex: 1 1;
  background-size: 100%;
}
.home-header .swiper-slide_content {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}
.home-header .swiper-slide_content_title {
  font-size: 40px;
  color: #fff;
  max-width: 1000px;
}
@media (max-width: 768px) {
  .home-header .swiper-slide_content_title {
    font-size: 22px !important;
 }
}
.swiper-button-next, .swiper-button-prev {
  opacity: 0 !important;
  width: 150px !important;
  height: 100vh !important;
  top: 0px !important;
  color: white !important;
}
.swiper-button-next:hover {
  opacity: 1 !important;
  margin-right: 50px !important;
}
.swiper-button-prev:hover {
  margin-left: 50px !important;
  opacity: 1 !important;
}
.swiper-button-next {
  right: -50px !important;
  margin-right: 50px !important;
}
.swiper-button-prev {
  left: -50px !important;
  margin-left: 50px !important;
}
.open-news .swiper-button-next, .open-news .swiper-button-prev {
  height: 500px !important;
}
.open-news .swiper-button-next::after, .open-news .swiper-button-prev::after {
  font-size: 30px !important;
}
